import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { Store } from '@ngxs/store'
import { finalize } from 'rxjs/operators'
import { TranslateModule } from '@ngx-translate/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { MatIcon } from '@angular/material/icon'
import { MatIconButton } from '@angular/material/button'

import { environment } from '../../../environments/environment'
import { Organizations } from '../../core/interfaces/organization/organizations'
import { Sponsor } from '../register-invited-organization/interfaces/sponsor'
import { AuthService } from '../../core/services'
import { PhoneNumberInput } from '../../shared/modules/phone-number/interfaces/phone-number-input'
import { PERSISTED_STATES } from '../../shared/states'
import { SetOrganization } from '../dashboard/states/dashboard.actions'
import { Organization } from '../../core/interfaces/organization/organization'
import { PhoneNumberComponent } from '../../shared/modules/phone-number/phone-number.component'
import { ButtonComponent } from '../../shared/components/button/button.component'
import { OrganizationSelectorComponent } from '../../shared/components'
import { RequestPasswordResetComponent } from '../../shared/modules/request-password-reset/request-password-reset.component'

@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatInput,
    MatIcon,
    MatIconButton,
    ButtonComponent,
    PhoneNumberComponent,
    RequestPasswordResetComponent,
    OrganizationSelectorComponent
  ]
})
export class LoginComponent implements OnInit {
  organizations: Organization[] = []
  loading: boolean = false
  loginForm: FormGroup<{
    phoneCountryCode: FormControl<string>
    phoneMobile: FormControl<string>
    password: FormControl<string>
  }>
  showOrganizationSelector: boolean = false
  sponsors: Sponsor[] = []
  showRequestPasswordReset: boolean = false
  redirectTo: string
  showPassword: boolean = false

  environment = environment

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.redirectTo = params.redirectTo
    })

    this.loginForm = this.formBuilder.nonNullable.group({
      phoneCountryCode: [localStorage.getItem('lastPhoneCode') || '', Validators.required],
      phoneMobile: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  handlePhoneNumberUpdate({ phoneCode, phoneNumber, isValid }: PhoneNumberInput): void {
    this.loginForm.controls.phoneCountryCode.patchValue(phoneCode)
    this.loginForm.controls.phoneMobile.patchValue(phoneNumber)
    this.loginForm.controls.phoneMobile.setErrors(isValid ? null : { invalidPhoneNumber: true })
    this.cdr.detectChanges()

    localStorage.setItem('lastPhoneCode', phoneCode)
  }

  login(): void {
    if (this.loginForm.invalid || this.loading) return

    this.loading = true
    this.authService
      .loginAdminDashboard(
        this.loginForm.value.phoneCountryCode! + this.loginForm.value.phoneMobile!,
        this.loginForm.value.password!,
        PERSISTED_STATES
      )
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((organizations: Organizations) => {
        this.organizations = organizations.clubs
        this.handleResponse()
      })
  }

  private handleResponse() {
    this.loading = false

    if (!this.organizations.length) {
      this.authService.logout()
      this.store.dispatch(new SetOrganization(null))
      window.location.href = environment.baseAppURL
    }

    this.showOrganizationSelector = true
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword
  }
}
